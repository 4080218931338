import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "Supporting Your Spouse During a Military Transition",
  "author": "Anthony",
  "date": "2018-04-25T05:26:52.000Z",
  "categories": ["Military Transition"],
  "slug": "supporting-your-spouse-during-a-military-transition",
  "draft": false,
  "meta_title": "Supporting Your Spouse During a Military Transition"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Whether you are crossing off the days on the calendar or wringing your hands in anticipation of the new adventures ahead, the `}<a parentName="p" {...{
        "href": "http://blog.purepost.co/set-yourself-up-for-success-before-transitioning-out-of-the-military/"
      }}>{`active duty to civilian career transition process`}</a>{` can be long and stressful.`}</p>
    <p>{`As most spouses know, there is really very little `}<em parentName="p">{`official work`}</em>{` that can be done on behalf of the soldier in helping them navigate, prepare, finalize the paperwork, attend workshops, and take phone calls on the Army side of the house.`}</p>
    <p><strong parentName="p">{`So, what can Military Spouses do?`}</strong></p>
    <p>{`You’ve been by your soldier’s side through it all. You’ve endured and outlasted the many odds against you and you are ready to be just as involved in this military transition process as everything else. But how?`}</p>
    <p>{`Here are a few ideas to get you started:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Networking`}</strong>{` - The military spouse community is small, but the connections are wide! Between units, schools, locations, volunteer organizations, and moves, you’ve created an expansive network of connections. In mind of employment and career options, reach out to those you know who’ve already positioned or who used specific services for job placement and resume assistance. A few emails with a, “Hey, we are getting out soon. Any advice or direction?” can go a long way. Sit down together with your spouse and make a list of 10-20 people you can reach out to and reconnect with. Use the strong community ties to your advantage.`}</li>
      <li parentName="ol"><strong parentName="li">{`Research`}</strong>{` - Knowledge is power. While most of the transition is out of your control, you can understand and utilize the many venues and services out there catered to the military community. Whether you start by filtering through the various VSOs (Veteran Service Organizations), veteran community organizations, or work to understand the purpose and place of the VA in your retired life, the more clarity and information you have to align with your changing path the better.`}</li>
      <li parentName="ol"><strong parentName="li">{`Active Listening`}</strong>{` - Resiliency training and re-integration phases often stress the importance of communication with your spouse. The cycle of stress is real. Mutual support and understanding of each others’ goals, needs, and wants during this phase of life will impact the overall emotional and mental success of the transition. Serious conversations on the course of a career, relocation, financial changes, and role reversal PRIOR to the transition beginning will assist in an easier roadmap as the transition plays out. But just as active duty life changes with little notice, this experience together will be no different. So stay open, connected, verbal, and also `}<em parentName="li">{`listening to your partner`}</em>{`.`}</li>
    </ol>
    <p>{`If you're eager to help your spouse gauge how their military experience can be translated into the civilian workforce, click below to quickly create an accurate resume for free!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      